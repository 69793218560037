// import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { tcolors } from "../config/Colors";
// import { useTranslation } from "react-i18next";
// import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
import BookingEngineAirBnB from "./BookingEngineAirBnB";

function BookingEngineContainer({ uuid, color, logo }) {
  const [isLoading] = React.useState(false);
  // const [bookingEngineMessage, setBookingEngineMessage] = React.useState({
  //   translation_obj: { text: "" },
  // });

  // const { t, i18n } = useTranslation("common");

  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://www.idaweb.eu/Archive/IDABE/v2/static/js/idabe.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   if (uuid) fetchBookingEngineMessage();
  // }, [t]);

  // const fetchBookingEngineMessage = async () => {
  //   setIsLoading(true);
  //   webService.getBookingEngineMessage(uuid).then(
  //     (result) => {
  //       const status = result.status;
  //       if (status === 200) setBookingEngineMessage(result.data);
  //       setIsLoading(false);
  //     },
  //     (error) => {}
  //   );
  // };

  return (
    <div className="hidden md:flex absolute flex-row items-center h-36 z-10">
      <div
        className="px-3 h-full w-48 "
        style={{ backgroundColor: tcolors[color + "-600"] }}
      >
        <Link to="/">
          <img
            className="w-full h-full object-contain rounded-md"
            src={logo}
            alt="structure"
          />
        </Link>
      </div>
      <div className="w-full h-full flex flex-col items-center justify-center bg-white rounded-br-2xl ">
        {isLoading && (
          <div className="w-full h-full flex items-center justify-center py-4 mx-auto">
            <LoadingIndicator />
          </div>
        )}

        <div className="flex items-center justify-center h-full">
          <BookingEngineAirBnB uuid={uuid} color={color} />
        </div>
        {/* <div
          className={`flex flex-row items mt-auto bg-white w-full rounded-br-2xl p-2 ${
            isLoading ? `hidden` : "visible"
          }`}
        >
          <p
            className="font-semibold text-sm tracking-wide"
            style={{ color: tcolors[color + "-800"] }}
          >
            <CheckCircleOutlined className="mr-2" />{" "}
            {bookingEngineMessage.translation_obj.text}
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default BookingEngineContainer;
