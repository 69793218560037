import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { tcolors } from "../config/Colors";
import { css } from "glamor";
import webService from "../services/web.service";

function PreventivoButton({ uuid }) {
  const { t, i18n } = useTranslation("common");
  const [config, setConfig] = React.useState({});

  const [hasPreventivo, setHasPreventivo] = useState(false);

  React.useEffect(() => {
    if (uuid) checkPreventivo();
  }, []);

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  const fetchConfig = async () => {
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
        }
      },
      (error) => {}
    );
  };

  const checkPreventivo = async () => {
    webService.hasPreventivo(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setHasPreventivo(result.data);
      },
      (error) => {}
    );
  };

  if (hasPreventivo) {
    return (
      <>
        <div
          className="hidden md:flex fixed right-0 top-[50%] z-[39] shadow-lg moving-left
      text-white p-3 rounded-tl-xl rounded-bl-xl items-center flex-col justify-center"
          {...css({
            backgroundColor: tcolors[config.color + "-600"],
            ":hover": { backgroundColor: tcolors[config.color + "-700"] },
          })}
          onClick={() =>
            window.open(
              "https://www.idabooking.eu/public/preventivo/request/" + uuid,
              "_blank"
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10 h-10 "
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>

          <div className="w-[60%] text-center mt-2">
            {t("booking_engine.getQuoteButton")}
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default PreventivoButton;
