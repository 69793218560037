import { css } from "glamor";
import React from "react";
import { tcolors } from "../config/Colors";

function CTA({ backgroundImage, title, text, actionText, actionUrl, color }) {
  return (
    <div className="max-w-5xl mx-auto py-10">
      <div
        className="flex flex-col gap-5 py-12 px-20 bg-cover rounded-none lg:rounded-lg shadow-md bg-center bg-no-repeat"
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("' +
            backgroundImage +
            '")',
        }}
      >
        <h1
          className="text-4xl tracking-wide font-bold"
          {...css({
            color: tcolors[color + "-50"],
          })}
        >
          {title}
        </h1>
        <div className="text-xl text-white">{text}</div>
        <div className="mt-3">
          <a
            className="text-white p-3 px-5 text-lg rounded-md cursor-pointer tracking-wider"
            href={actionUrl}
            {...css({
              backgroundColor: tcolors[color + "-500"],
              ":hover": { backgroundColor: tcolors[color + "-600"] },
            })}
          >
            {actionText}
          </a>
        </div>
      </div>
    </div>
  );
}

export default CTA;
