import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { COLOR, ID_CONFIG, USE_VIDEO, UUID } from "./config/backendConfig";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("lang")
    ? localStorage.getItem("lang").toLowerCase()
    : "it", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    it: {
      common: common_it,
    },
  },
});

const element = document.getElementById("root");
const root = ReactDOM.createRoot(element);
root.render(
  <I18nextProvider i18n={i18next}>
    <App uuid={UUID} useVideo={USE_VIDEO} color={COLOR} id_config={ID_CONFIG} />
    <Toaster />
  </I18nextProvider>
);
