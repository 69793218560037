import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import { ImageCDN } from "./ImageCDN";
import { tcolors } from "../config/Colors";
import { css } from "glamor";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
import { useTranslation } from "react-i18next";

function BestChoice({ uuid, color }) {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = React.useState(false);
  const [bestChoices, setBestChoices] = React.useState([]);

  React.useEffect(() => {
    if (uuid) fetchBestChoice();
  }, [t]);

  const fetchBestChoice = async () => {
    setIsLoading(true);
    webService.getBestChoice(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setBestChoices(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <section className="relative max-w-4xl flex items-center justify-center -mt-6 z-20 mx-auto">
      <div className="w-full flex flex-col">
        <div
          className="bg-indigo-50 rounded-xl shadow-xl shadow-gray-300"
          {...css({
            backgroundColor: tcolors[color + "-200"],
          })}
        >
          <div className="w-full flex flex-col text-center p-5 ">
            <h1 className="text-md sm:text-xl md:text-2xl uppercase font-semibold tracking-wider text-white">
              {t("best_choice.title")}
            </h1>
            <p className="text-xs sm:text-base mt-1 text-slate-500">
              {t("best_choice.subtitle")}
            </p>
          </div>
          <div className="w-full flex flex-col bg-white rounded-t-3xl overflow-hidden">
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center py-4">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                {bestChoices?.map((choice, index) => (
                  <div
                    key={"choice-" + index}
                    className={`flex flex-row border-b border-gray-300 items-stretch`}
                  >
                    {/* First col */}
                    <div
                      className={`w-1/4 flex text-center px-2 ${
                        choice.is_official
                          ? "flex-col justify-start"
                          : `flex-col-reverse sm:flex-row-reverse gap-2 justify-end`
                      } !items-center py-5`}
                    >
                      <p className="text-xs sm:text-md text-center text-gray-500 font-semibold">
                        {choice.ota.buyer_n}
                      </p>
                      <div
                        className={`relative ${
                          choice.is_official ? "mt-3 w-4/5" : "w-6 ml-0 sm:ml-6"
                        }`}
                      >
                        <img
                          src={
                            choice.is_official
                              ? ImageCDN(choice.logo, "200x50")
                              : choice.image
                          }
                          alt="ota-url"
                          className="object-contain w-full"
                        />
                      </div>
                    </div>

                    {/* Second col */}
                    <div className="w-2/4 flex flex-col justify-start p-5 mb-auto">
                      {choice.free_giveaway_obj.text && (
                        <>
                          <span className="font-bold text-green-500 uppercase text-sm">
                            IN OMAGGIO:
                          </span>
                          <div
                            key={
                              "free-" +
                              choice.free_giveaway_obj.id_translation +
                              "-" +
                              index
                            }
                            className="rounded-xl sm:rounded-full text-[10px] sm:text-[11px]
                      flex items-center mt-2 uppercase
                      font-bold w-fit px-2 py-1 bg-blue-500 text-white text-wider"
                          >
                            {choice.free_giveaway_obj.text}
                          </div>
                        </>
                      )}

                      <div
                        className={`${
                          choice.is_official && "mt-3"
                        } flex flex-col`}
                      >
                        {choice.pros?.map((el, index) => (
                          <>
                            {choice.is_official ? (
                              <div
                                key={"pros-" + choice.id + "-" + index}
                                className="flex flex-row items-center gap-2"
                              >
                                <CheckOutlined className="text-green-500" />
                                <span className="text-xs sm:text-sm text-gray-500">
                                  {el.choice_text_obj.text}
                                </span>
                              </div>
                            ) : (
                              <div
                                key={"pros-" + choice.id + "-" + index}
                                className="flex flex-col gap-2 italic text-gray-400 text-sm"
                              >
                                {el.choice_text_obj.text}
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>

                    {/* Third col */}
                    <div className="w-2/4 sm:w-1/4 flex flex-row justify-center items-center gap-5 p-5">
                      <div className="flex flex-col items-end w-full">
                        {choice.is_official ? (
                          <>
                            <div className="text-gray-600 text-md sm:text-lg line-through font-semibold">
                              {choice.striked_price} €
                            </div>
                            <div className="text-green-600 font-bold text-lg sm:text-4xl">
                              {parseFloat(choice.price).toFixed(2)} €
                            </div>
                          </>
                        ) : (
                          <div className="text-gray-600 text-md sm:text-lg font-semibold ">
                            {parseFloat(choice.price).toFixed(2)} €
                          </div>
                        )}
                      </div>
                      <div
                        className="flex sm:hidden"
                        onClick={() => window.open(choice.url, "_blank")}
                      >
                        <RightOutlined />
                      </div>
                    </div>

                    {/* Fourth col desktop*/}
                    <div className="w-1/4 hidden sm:flex">
                      {choice.is_official ? (
                        <div
                          onClick={() => window.open(choice.url, "_blank")}
                          className="w-full h-full p-6 transition rounded-tr-3xl 
                        flex items-center justify-center font-semibold text-center
                        text-white text-xl cursor-pointer ease-out"
                          {...css({
                            backgroundColor: tcolors[color + "-600"],
                            ":hover": {
                              backgroundColor: tcolors[color + "-700"],
                            },
                          })}
                        >
                          {t("best_choice.book_now_best_price")}
                        </div>
                      ) : (
                        <div
                          className="w-full flex flex-col justify-center
                     p-5 text-xl text-white bg-gray-300 text-center 
                     border-b border-b-white cursor-pointer hover:bg-gray-400 
                     transition ease-out"
                          onClick={() => window.open(choice.url, "_blank")}
                        >
                          {t("best_choice.book_now")}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestChoice;
