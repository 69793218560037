import React from "react";
import Banner from "../../components/Banner";
import { ImageCDN } from "../../components/ImageCDN";
import SideMenu from "../../components/SideMenu";

import {
  ArrowRightOutlined,
  FullscreenOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import ButtonBar from "../../components/ButtonBar";
import webService from "../../services/web.service";
import { css } from "glamor";
import { tcolors } from "../../config/Colors";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/LoadingIndicator";
import { STRUCTURE_NAME } from "../../config/backendConfig";

const PTitle = ({ children }) => (
  <div className="font-semibold py-5">{children}</div>
);

function Privacy({ uuid }) {
  const { t } = useTranslation("common");

  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    document.title = t("titles.privacy", { structure_name: STRUCTURE_NAME });
    if (uuid) {
      fetchConfig();
    }
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setConfig(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  const navigate = useNavigate();

  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("privacy.title_privacy")}
        </div>
      </div>

      <div className="max-w-7xl mx-auto my-24 px-10 lg:px-0">
        <div className="grid grid-cols-12">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center text-center mx-auto py-4">
              <LoadingIndicator />
            </div>
          ) : (
            <div className="flex flex-col col-span-12">
              <div className="w-full">
                In questa pagina si descrivono le modalità di gestione del sito
                in riferimento al trattamento dei dati personali degli utenti
                che lo consultano. Si tratta di un’informativa che è resa anche
                ai sensi dell’art. 13 del d.lgs. n. 196/2003 – Codice in materia
                di protezione dei dati personali a coloro che interagiscono con
                i servizi del sito web AGRITURISMO ALL'ALBARO Data di ultimo
                aggiornamento: Venerdì 29 Maggio 2015.
              </div>
              <PTitle>INDICE DEI CONTENUTI</PTitle>
              <div className="w-full">
                FINALITÀ E MODALITÀ DEL TRATTAMENTO DEI DATI PERSONALI <br></br>
                SOGGETTI CHE POSSONO VENIRE A CONOSCENZA DEI SUOI DATI<br></br>
                SOGGETTI AI QUALI I SUOI DATI PERSONALI POTRANNO ESSERE
                COMUNICATI<br></br> TITOLARE E RESPONSABILE DEL TRATTAMENTO
                <br></br> LUOGO DI TRATTAMENTO DEI DATI<br></br> DATI FORNITI
                VOLONTARIAMENTE DALL'UTENTE<br></br> TIPI DI DATI TRATTATI -
                DATI DI NAVIGAZIONE<br></br> I SUOI DIRITTI<br></br> COOKIE
              </div>
              <PTitle>
                FINALITÀ E MODALITÀ DEL TRATTAMENTO DEI DATI PERSONALI
              </PTitle>
              <div className="w-full">
                Fornire il servizio in precedenza indicato (di seguito
                “Servizio”) e da Lei liberamente scelto, nonché per consentire a
                AGRITURISMO ALL'ALBARO di svolgere l’indagine relativa a detto
                Servizio ricevuto; Fornire informazioni commerciali e/o
                promozionali nonché inviare materiale pubblicitario su prodotti,
                servizi ed altre attività di AGRITURISMO ALL'ALBARO. Il
                trattamento dei dati per ciascuna delle finalità di cui sopra
                avverrà con modalità cartacea, automatizzata e telematica ed, in
                particolare, a mezzo posta ordinaria od elettronica, telefono e
                telefax. SOGGETTI CHE POSSONO VENIRE A CONOSCENZA DEI SUOI DATI
                I Suoi dati personali, raccolti tramite procedure telematiche
                all’interno del sito web di proprietà di AGRITURISMO ALL'ALBARO
                potranno essere trattati da incaricati del trattamento, anche
                esterni, preposti alla gestione del Servizio richiesto ed alle
                previste attività di marketing e dal/dai Responsabile/i del
                trattamento o dalle Società cui i dati medesimi sono stati
                comunicati come di seguito previsto. SOGGETTI AI QUALI I SUOI
                DATI PERSONALI POTRANNO ESSERE COMUNICATI I Suoi dati personali
                potranno essere comunicati a terzi per adempiere ad obblighi di
                legge, ovvero per rispettare ordini provenienti da pubbliche
                autorità, ovvero per esercitare un diritto in sede giudiziaria.
                TITOLARE E RESPONSABILE DEL TRATTAMENTO Titolare del trattamento
                è Agriturismo all'albaro con sede in Via Albero, 301 37056
                Salizzole, Verona, Italy LUOGO DI TRATTAMENTO DEI DATI I
                trattamenti connessi ai servizi web di questo sito hanno luogo
                presso la predetta sede della società e sono curati solo da
                personale autorizzato, o da eventuali incaricati di occasionali
                operazioni di manutenzione. Nessun dato derivante dal servizio
                web viene comunicato o diffuso. I dati personali forniti dagli
                utenti che inoltrano richieste di invio di materiale informativo
                (mailing list, newsletter, richiesta informazioni, ecc.) sono
                utilizzati al solo fine di eseguire il servizio o la prestazione
                richiesta e non sono comunicate a terzi. DATI FORNITI
                VOLONTARIAMENTE DALL'UTENTE L’invio facoltativo, esplicito e
                volontario di posta elettronica agli indirizzi indicati su
                questo sito comporta la successiva acquisizione dell’indirizzo
                del mittente, necessario per rispondere alle richieste, nonché
                degli eventuali altri dati personali inseriti nella missiva.
              </div>

              <PTitle>TIPI DI DATI TRATTATI - DATI DI NAVIGAZIONE</PTitle>
              <div className="w-full">
                I sistemi informatici e le procedure software preposte al
                funzionamento di questo sito web acquisiscono, nel corso del
                loro normale esercizio, alcuni dati personali la cui
                trasmissione è implicita nell’uso dei protocolli di
                comunicazione di Internet. Si tratta di informazioni che non
                sono raccolte per essere associate a interessati identificati,
                ma che per loro stessa natura potrebbero, attraverso
                elaborazioni ed associazioni con dati detenuti da terzi,
                permettere di identificare gli utenti. In questa categoria di
                dati rientrano gli indirizzi IP o i nomi a dominio dei computer
                utilizzati dagli utenti che si connettono al sito, gli indirizzi
                in notazione URI (Uniform Resource Identifier) delle risorse
                richieste, l’orario della richiesta, il metodo utilizzato nel
                sottoporre la richiesta al server, la dimensione del file
                ottenuto in risposta, il codice numerico indicante lo stato
                della risposta data dal server (buon fine, errore, ecc.) ed
                altri parametri relativi al sistema operativo e all’ambiente
                informatico dell’utente (come per esempio il browser utilizzato
                e la risoluzione del monitor). Questi dati vengono utilizzati al
                solo fine di ricavare informazioni statistiche anonime sull’uso
                del sito e per controllarne il corretto funzionamento. I dati
                potrebbero essere utilizzati per l’accertamento di
                responsabilità in caso di ipotetici reati informatici ai danni
                del sito. I SUOI DIRITTI Ai sensi dell’art. 7 del D. Lgs. n.
                196/2003 è suo diritto accedere ai Suoi dati, ottenere senza
                ritardo l’aggiornamento o la cancellazione per eventuali
                trattamenti in violazione di legge, opporsi al trattamento dei
                Suoi dati per finalità commerciale o pubblicitaria nonché
                richiedere l’elenco completo ed aggiornato di tutti i
                Responsabili del trattamento, scrivendo a AGRITURISMO ALL'ALBARO
                – Via Albero, 301 37056 Salizzole, Verona, Italy. ART. 7.
                DIRITTO DI ACCESSO AI DATI PERSONALI ED ALTRI DIRITTI
                L’interessato ha diritto di ottenere la conferma dell’esistenza
                o meno di dati personali che lo riguardano, anche se non ancora
                registrati, e la loro comunicazione in forma intelligibile.
                L’interessato ha diritto di ottenere l’indicazione: -
                dell’origine dei dati personali; - delle finalità e modalità del
                trattamento; - della logica applicata in caso di trattamento
                effettuato con l’ausilio di strumenti elettronici; - degli
                estremi identificativi del titolare, dei responsabili e del
                rappresentante designato ai sensi dell’articolo 5, comma 2 del
                D. Lgs. n. 196/2003; - dei soggetti o delle categorie di
                soggetti ai quali i dati personali possono essere comunicati o
                che possono venirne a conoscenza in qualità di rappresentante
                designato nel territorio dello Stato, di responsabili o
                incaricati.
              </div>
              <PTitle>L’INTERESSATO HA DIRITTO DI OTTENERE:</PTitle>
              <div className="w-full">
                l’aggiornamento, la rettifica ovvero, quando vi ha interesse,
                l’integrazione dei dati; la cancellazione, la trasformazione in
                forma anonima o il blocco dei dati trattati in violazione di
                legge, compresi quelli di cui non è necessaria la conservazione
                in relazione agli scopi per i quali i dati sono stati raccolti o
                successivamente trattati; l’attestazione che le operazioni di
                cui alle lettere a) e b) sono state portate a conoscenza, anche
                per quanto riguarda il loro contenuto, di coloro ai quali i dati
                sono stati comunicati o diffusi, eccettuato il caso in cui tale
                adempimento si rivela impossibile o comporta un impiego di mezzi
                manifestamente sproporzionato rispetto al diritto tutelato.
              </div>

              <PTitle>
                L’INTERESSATO HA DIRITTO DI OPPORSI, IN TUTTO O IN PARTE:
              </PTitle>
              <div className="w-full">
                per motivi legittimi al trattamento dei dati personali che lo
                riguardano, ancorché pertinenti allo scopo della raccolta; al
                trattamento di dati personali che lo riguardano a fini di invio
                di materiale pubblicitario o di vendita diretta o per il
                compimento di ricerche di mercato o di comunicazione
                commerciale.
              </div>

              <PTitle>COOKIE</PTitle>
              <div className="w-full">
                Per far funzionare bene questo sito installiamo sul Suo
                dispositivo di navigazione web dei file di dati che chiamati
                “cookies”. Questa tecnologia è comune alla maggior parte dei
                siti web. COSA SONO I COOKIE? I cookie sono piccoli file di
                testo che i siti visitati inviano al terminale (computer,
                tablet, smartphone, notebook) dell’utente, dove vengono
                memorizzati, per poi essere ritrasmessi agli stessi siti alla
                visita successiva. Sono usati per eseguire autenticazioni
                informatiche, monitoraggio di sessioni e memorizzazione di
                informazioni sui siti.Questi cookie sono definiti tecnici. Senza
                l’uso dei cookie “tecnici” alcune operazioni risulterebbero
                molto complesse o impossibili da eseguire. Attraverso i cookie
                si può anche monitorare la navigazione, raccogliere dati su
                gusti, abitudini, scelte personali che consentono la
                ricostruzione di dettagliati profili dei consumatori. Questi
                cookie vengono definiti di “profilazione”. COOKIE UTILIZZATI SUL
                SITO WEB DI AGRITURISMO ALL'ALBARO Il sito Web AGRITURISMO
                ALL'ALBARO utilizza ai fini della corretta navigazione del
                proprio portale solo ed eslcusivamente cookie tecnici o di terze
                parti. Non sono utilizzati cookie di profilazione. GOOGLE
                ANALYTICS I cookie tecnici Google Analytics consentono di capire
                come i visitatori utilizzano il sito web AGRITURISMO ALL'ALBARO.
                I dati vengono raccolti in forma anonima per fini statistici.
                Maggiori informazioni sui cookie Google Analytics sono
                disponibili all’indirizzo
                https://support.google.com/analytics/answer/6004245 COME
                DISABILITARE I COOKIE Se desidera disattivare i cookie di Google
                Analytics, può scaricare e installare un componente aggiuntivo
                per il browser web sviluppato da Google. Il componente
                aggiuntivo del browser per la disattivazione di Google Analytics
                è disponibile per Internet Explorer (versioni 7 e 8), Google
                Chrome (4.x e versioni successive) e Mozilla Firefox (3.5 e
                versioni successive), e si può scaricare all’indirizzo
                http://tools.google.com/dlpage/gaoptout?hl=it . Tutti i cookie
                sono disattivabili dalle impostazioni del Suo browser. Qualora
                si decidesse di rimuovere parzialmente o totalmente i cookie, è
                bene ricordare che l’utilizzo del sito web #name può essere
                influenzato dalla rimozione dei cookie. La sezione Guida del
                browser Web dovrebbe contenere informazioni sulla gestione delle
                impostazioni per i cookie. E’ possibile fare riferimento a
                queste istruzioni (in lingua inglese) per rimuovere i cookie
                tramite browser: Microsoft Internet Explorer:
                http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies
                Google Chrome:
                https://support.google.com/chrome/answer/95647?hl=en Mozilla
                Firefox:
                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-…
                Opera: http://help.opera.com/Windows/10.20/en/cookies.html Apple
                Safari: http://support.apple.com/kb/ph11913
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy uuid={uuid} color={config.color} />
      <ButtonBar uuid={uuid} color={config.color} />
    </>
  );
}

export default Privacy;
