import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { Button } from "antd";
import { Popover, InputNumber, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { encode as base64_encode } from "base-64";
import { useTranslation } from "react-i18next";

import * as dayjs from "dayjs";
import "dayjs/locale/it"; // import locale
import "dayjs/locale/en"; // import locale
import { tcolors } from "../config/Colors";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";

const locales_dayjs = {
  it: import("dayjs/locale/it"),
  en: import("dayjs/locale/en"),
};

const setDayJsLocale = (language) => {
  locales_dayjs[language].then(() => {
    dayjs.locale(language);
  });
};

function BookingEngineAirBnB({ uuid, color }) {
  const [rooms, setRooms] = React.useState([
    {
      id: 1,
      adults: 2,
      children: 0,
    },
  ]);
  const { t, i18n } = useTranslation("common");
  dayjs.locale(i18n.language);

  const [hasBookingEngine, setHasBookingEngine] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState("");
  const [beDates, setBeDates] = useState([
    dayjs(new Date()).format("DD MMM"),
    dayjs(addDays(new Date(), 2)).format("DD MMM"),
  ]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 2),
      key: "selection",
    },
  ]);
  const [structureInfo, setStructureInfo] = React.useState({
    reg_business_name: "",
    reg_address: "",
    reg_zip_code: "",
    reg_country: "",
    reg_province: "",
    reg_municipality: "",
    reg_phone: "",
    reg_email: "",
  });

  React.useEffect(() => {
    if (uuid) fetchFooterInfo();
  }, [t]);

  React.useEffect(() => {
    if (uuid) checkBookingEngine();
  }, []);

  React.useEffect(() => {
    setBeDates([
      dayjs(state[0].startDate).locale(i18n.language).format("DD MMM"),
      dayjs(state[0].endDate).locale(i18n.language).format("DD MMM"),
    ]);
  }, [t]);

  const fetchFooterInfo = async () => {
    webService.getFooterInfo(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setStructureInfo(result.data[0]);
      },
      (error) => {}
    );
  };

  const checkBookingEngine = async () => {
    setLoading(true);
    webService.hasBookingEngine(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setHasBookingEngine(result.data);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const PopoverContent = ({ rooms, setRooms }) => (
    <div className="flex flex-col items-start w-full rounded-xl">
      {rooms?.map((room, index) => (
        <div
          key={"room-" + index}
          className={`w-full mt-5 ${
            index < rooms.length - 1 ? "border-b border-gray-200" : ""
          } pb-5`}
        >
          <div className="flex flex-row items-center justify-center mb-3">
            {index > 0 && (
              <Tooltip title="Rimuovi stanza">
                <Button
                  size="small"
                  shape="circle"
                  type="dashed"
                  className="flex items-center justify-center mr-2"
                  onClick={() => {
                    setRooms(rooms.filter((el) => el.id !== room.id));
                  }}
                  style={{ display: "flex" }}
                >
                  <CloseOutlined className="m-0 p-0" />
                </Button>
              </Tooltip>
            )}

            <span className="font-semibold text-lg">Camera #{index + 1}</span>
          </div>
          <div className="flex flex-row items-center w-full">
            <span>Adulti</span>
            <InputNumber
              min={index > 0 && room.children > 0 ? 0 : 1}
              defaultValue={room.adults}
              onChange={(value) => {
                setRooms(
                  rooms.map((el) => {
                    if (el.id === room.id) el.adults = value;
                    return el;
                  })
                );
              }}
              style={{ marginLeft: "auto" }}
            />
          </div>
          <div className="flex flex-row items-center w-full mt-2">
            <span>Bambini</span>
            <InputNumber
              min={room.adults > 0 ? 0 : 1}
              defaultValue={room.children}
              onChange={(value) => {
                setRooms(
                  rooms.map((el) => {
                    if (el.id === room.id) el.children = value;
                    return el;
                  })
                );
              }}
              style={{ marginLeft: "auto" }}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const TitlePopover = (
    <div className="flex flex-row items-center">
      <Button
        className="ml-auto"
        onClick={() =>
          setRooms([...rooms, { id: rooms.length + 1, adults: 2, children: 0 }])
        }
      >
        {t("booking_engine.add_room")}
      </Button>
    </div>
  );

  function getGuestsCount() {
    let guests = 0;
    rooms.map((el) => {
      guests += el.adults + el.children;
      return el;
    });
    return guests;
  }

  const search = () => {
    const search_obj = {
      date_from: dayjs(state[0].startDate).format("DD/MM/YYYY"),
      date_to: dayjs(state[0].endDate).format("DD/MM/YYYY"),
      lang: i18n.language,
      promo_code: "",
      rooms: rooms,
      uuid: uuid,
    };
    window.open(
      "https://www.idabooking.eu/Home/search_base/" +
        base64_encode(JSON.stringify(search_obj)),
      "_blank"
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`flex ${
          !hasBookingEngine
            ? "flex-col gap-3 items-start justify-start text-left"
            : "flex-row gap-10"
        } items-center`}
      >
        <div className="flex flex-row items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-5 h-5 "
            style={{ color: tcolors[color + "-500"] }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
            />
          </svg>
          <span className="text-sm tracking-wider">
            {structureInfo.reg_phone}
          </span>
        </div>
        <div className="flex flex-row items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-5 h-5"
            style={{ color: tcolors[color + "-500"] }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
            />
          </svg>

          <a
            href={"mailto:" + structureInfo.reg_email}
            className="text-sm tracking-wider"
          >
            {structureInfo.reg_email}
          </a>
        </div>
      </div>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {hasBookingEngine && (
            <div
              className={`flex flex-col md:flex-row items-center justify-center rounded-full
            ${
              focused ? "bg-gray-200" : "bg-gray-100"
            } border border-gray-300 relative`}
            >
              <div
                className={`flex flex-col hover:bg-gray-200 transition  py-3 px-5 rounded-full cursor-pointer ${
                  focused === "checkin" && "shadow-lg bg-white hover:!bg-white"
                }`}
                onClick={() => {
                  if (open) {
                    setOpen(false);
                    setFocused("");
                  } else {
                    setOpen(true);
                    setFocused("checkin");
                  }
                }}
              >
                <span className="font-semibold text-sm">
                  {t("booking_engine.checkin")}
                </span>

                {!state[0].startDate ? (
                  <span className="font-thin text-gray-400 text-sm">
                    {t("booking_engine.add_dates")}
                  </span>
                ) : (
                  <span className="font-regular text-sm">{beDates[0]}</span>
                )}
              </div>

              <div
                className={`mx-2 h-[30px] border-l ${
                  focused ? "border-gray-400" : "border-gray-300"
                }`}
              />

              <div
                className={`flex flex-col hover:bg-gray-200 transition py-3 px-5 rounded-full cursor-pointer ${
                  focused === "checkout" && "shadow-lg bg-white hover:!bg-white"
                }`}
                onClick={() => {
                  if (open) {
                    setOpen(false);
                    setFocused("");
                  } else {
                    setOpen(true);
                    setFocused("checkout");
                  }
                }}
              >
                <span className="font-semibold text-sm">
                  {t("booking_engine.checkout")}
                </span>
                {!state[0].endDate ? (
                  <span className="font-thin text-gray-400 text-sm">
                    {t("booking_engine.add_dates")}
                  </span>
                ) : (
                  <span className="font-regular text-sm">{beDates[1]}</span>
                )}
              </div>

              <div
                className={`mx-2 h-[30px] border-l ${
                  focused ? "border-gray-400" : "border-gray-300"
                }`}
              />

              <Popover
                content={<PopoverContent rooms={rooms} setRooms={setRooms} />}
                title={TitlePopover}
                trigger="click"
                open={focused === "guests"}
              >
                <div
                  className={`${
                    focused === "guests" && "shadow-lg bg-white hover:!bg-white"
                  } flex flex-col hover:bg-gray-200 transition py-3 px-5 rounded-full cursor-pointer`}
                  onClick={() => {
                    setOpen(false);
                    if (!focused) {
                      setFocused("guests");
                    } else {
                      setFocused();
                    }
                  }}
                >
                  <span className="font-semibold text-sm">Ospiti</span>
                  <span className="font-thin text-gray-400 text-sm">
                    {rooms.length}{" "}
                    {rooms.length > 1
                      ? t("booking_engine.rooms")
                      : t("booking_engine.room")}{" "}
                    &#x2022; {getGuestsCount()}{" "}
                    {getGuestsCount() > 1
                      ? t("booking_engine.guests")
                      : t("booking_engine.guest")}
                  </span>
                </div>
              </Popover>

              <div className="flex justify-end mr-2">
                <button
                  className="bg-indigo-600
           hover:bg-indigo-700 transition 
           ease-out rounded-full text-white p-2
           flex flex-row items-center gap-2"
                  onClick={search}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                  {focused && (
                    <span className="font-semibold text-sm tracking-wider">
                      {t("booking_engine.search")}
                    </span>
                  )}
                </button>
              </div>

              <div
                className={`${
                  open ? "visible" : "hidden"
                } absolute bottom-0 top-[65px] flex flex-col !bg-white h-full rounded-md`}
              >
                <DateRange
                  onChange={(item) => {
                    console.log(item.selection);
                    setState([item.selection]);
                    setBeDates([
                      dayjs(item.selection.startDate).format("DD MMM"),
                      dayjs(item.selection.endDate).format("DD MMM"),
                    ]);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  locale={locales[i18n.language]}
                  minDate={new Date()}
                  className="flex-1"
                  inputRanges={[]}
                />
                <div className="w-full bg-white p-2 rounded-b-md flex justify-end">
                  <Button
                    type="primary"
                    onClick={() => {
                      setOpen(false);
                      setFocused();
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BookingEngineAirBnB;
