import React from "react";
import Advisor from "../../components/Advisor";
import Banner from "../../components/Banner";
import BestChoice from "../../components/BestChoice";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import { ImageCDN } from "../../components/ImageCDN";
import Newsletter from "../../components/Newsletter";
import RoomsPreview from "../../components/RoomsPreview";
import SideMenu from "../../components/SideMenu";
import StructurePreview from "../../components/StructurePreview";
import StructureServices from "../../components/StructureServices";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { STRUCTURE_NAME } from "../../config/backendConfig";
import PreventivoButton from "../../components/PreventivoButton";
import HomeParagraph from "../../components/Custom/HomeParagraph";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";

function Home({ uuid, useVideo }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.home", { structure_name: STRUCTURE_NAME });
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Banner
            uuid={uuid}
            useVideo={true}
            color={config.color}
            logo={config.white_logo}
          />
          <SideMenu uuid={uuid} color={config.color} />

          <main className=" bg-gray-100">
            <div className="mx-5 sm:mx-3 lg:mx-auto max-w-7xl flex flex-col">
              <BestChoice uuid={uuid} color={config.color} />
              <HomeParagraph />

              {/* <div className="flex items-center justify-center">
                <div
                  className="py-5 px-10 font-semibold text-white transition-all cursor-pointer text-center w-fit text-xl rounded-md"
                  {...css({
                    backgroundColor: tcolors[config.color + "-600"],
                    ":hover": {
                      backgroundColor: tcolors[config.color + "-700"],
                    },
                  })}
                  onClick={() => {
                    window.open("https://piccolofioreprimiero.com/", "_blank");
                  }}
                >
                  🌺 {t("custom.home_button_1")} 🌺
                </div>
              </div> 
              <Newsletter uuid={uuid} color={config.color} />
              */}
            </div>
          </main>

          <section className=" bg-white flex items-center pt-20">
            <StructurePreview uuid={uuid} />
          </section>

          {/* <section className="bg-white flex items-center justify-center">
            <StructureServices uuid={uuid} />
          </section> */}

          {/* <Advisor
            id_advisor="11"
            color={config.color}
            image={"https://www.idaweb.eu/Archive/Media/150/18TAOU0EDS.jpg"}
          /> */}

          <section className="bg-white flex items-center justify-center">
            <RoomsPreview uuid={uuid} color={config.color} />
          </section>

          <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
          <FooterMadeBy uuid={uuid} color={config.color} />
          <ButtonBar uuid={uuid} color={config.color} />
        </>
      )}
    </>
  );
}

export default Home;
