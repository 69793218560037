import axios from "axios";
import { ID_CONFIG } from "../config/backendConfig";

const BACKEND_URL = "https://backend.idabooking.eu/api/web/";
//const BACKEND_URL = "https://localhost:44392/api/web/";
// const language = "IT";

function getLanguage() {
  var lang = localStorage.getItem("lang");
  if (!lang) lang = "IT";
  return lang;
}

class webService {
  getConfig(uuid) {
    return axios.get(BACKEND_URL + "config/" + uuid + "/" + ID_CONFIG);
  }
  getBanners(uuid) {
    return axios.get(BACKEND_URL + "banner/" + uuid + "/" + ID_CONFIG);
  }
  getPages(uuid) {
    return axios.get(
      BACKEND_URL + "pages/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getBestChoice(uuid) {
    return axios.get(
      BACKEND_URL +
        "best_choice/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  getStructurePreview(uuid) {
    return axios.get(
      BACKEND_URL +
        "structure_preview/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  getStructureServices(uuid) {
    return axios.get(
      BACKEND_URL +
        "structure_services/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  getAdvisor(id_advisor) {
    return axios.get(
      BACKEND_URL + "advisor/" + getLanguage() + "/" + id_advisor
    );
  }
  getRooms(uuid) {
    return axios.get(
      BACKEND_URL + "rooms/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getSurroundings(uuid) {
    return axios.get(
      BACKEND_URL +
        "surroundings/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  getImagesGallery(uuid) {
    return axios.get(BACKEND_URL + "gallery/" + uuid + "/" + ID_CONFIG);
  }
  getBookingEngineMessage(uuid) {
    return axios.get(
      BACKEND_URL +
        "booking_engine/message/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  subscribeNewsletter(uuid, email) {
    return axios.post(
      BACKEND_URL + "email/subscribe/" + uuid + "/" + ID_CONFIG,
      email
    );
  }
  getRoomDetails(uuid, id_room) {
    return axios.get(
      BACKEND_URL + "room/details/" + getLanguage() + "/" + uuid + "/" + id_room
    );
  }
  getImages(id_room) {
    return axios.get(BACKEND_URL + "room/images/" + id_room);
  }
  getFooterInfo(uuid) {
    return axios.get(BACKEND_URL + "footer/" + getLanguage() + "/" + uuid);
  }
  getButtonBar(uuid) {
    return axios.get(BACKEND_URL + "button_bar/" + getLanguage() + "/" + uuid);
  }
  newMessageContact(data) {
    return axios.post(BACKEND_URL + "contact", data);
  }
  getRestaurant(uuid) {
    return axios.get(
      BACKEND_URL + "restaurant/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getServices(uuid) {
    return axios.get(
      BACKEND_URL + "services/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getWhereAreWe(uuid) {
    return axios.get(
      BACKEND_URL + "wherearewe/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getShop(uuid) {
    return axios.get(
      BACKEND_URL + "shop/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
  getFooterLinks(uuid) {
    return axios.get(
      BACKEND_URL +
        "footer/links/" +
        getLanguage() +
        "/" +
        uuid +
        "/" +
        ID_CONFIG
    );
  }
  hasBookingEngine(uuid) {
    return axios.get(BACKEND_URL + "service/booking_engine/" + uuid);
  }
  hasPreventivo(uuid) {
    return axios.get(BACKEND_URL + "service/preventivo/" + uuid);
  }
  getEvents(uuid) {
    return axios.get(
      BACKEND_URL + "events/" + getLanguage() + "/" + uuid + "/" + ID_CONFIG
    );
  }
}
export default new webService();
