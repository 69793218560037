import React from "react";
import { useTranslation } from "react-i18next";

function HomeParagraph() {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col gap-5 text-center py-20">
      <h3 className="text-3xl font-semibold">{t("custom.home_header_1")}</h3>
      <p>{t("custom.home_body_1")}</p>
      <p>
        {t("custom.home_body_2")}
        <br></br>
        <br></br>
        <b>{t("custom.home_body_3")}</b>
      </p>
    </div>
  );
}

export default HomeParagraph;
