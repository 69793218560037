import { StyleProvider } from "@ant-design/cssinjs";
import React from "react";
// import { useParams } from "react-router-dom";
import BookingEngineContainer from "../../components/BookingEngineContainer";
import SideMenu from "../../components/SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import RoomCarousel from "../../components/RoomCarousel";
import ServiceRoom from "../../components/ServiceRoom";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import ButtonBar from "../../components/ButtonBar";
import { css } from "glamor";
import { tcolors } from "../../config/Colors";
import webService from "../../services/web.service";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { STRUCTURE_NAME } from "../../config/backendConfig";

function Room({ uuid, color }) {
  const { id } = useParams();

  const [room, setRoom] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [config, setConfig] = React.useState({});
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, [t]);

  React.useEffect(() => {
    document.title = t("titles.room", {
      structure_name: STRUCTURE_NAME,
      room_name: room.name,
    });
  }, [room]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setConfig(result.data);
        fetchRoomDetails();
      },
      (error) => {}
    );
  };

  const fetchRoomDetails = async () => {
    webService.getRoomDetails(uuid, id).then(
      (result) => {
        const status = result.status;
        if (status === 200) setRoom(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <StyleProvider hashPriority="high">
      <BookingEngineContainer
        uuid={uuid}
        color={config.color}
        logo={config.white_logo}
      />

      <div className=" bg-gray-100 flex items-center justify-center">
        <div
          className="
            max-w-7xl pb-5
            "
        >
          <SideMenu uuid={uuid} color={config.color} />

          {isLoading ? (
            <div className="w-full flex items-center justify-center text-center mx-auto py-4 h-screen">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              <div className=" bg-slate-100 h-full p-5 md:p-24 flex flex-col gap-4">
                <div className=" !mt-20 md:!mt-32 flex flex-col md:flex-row items-center gap-3 md:gap-16">
                  <h1 className="flex-1 text-4xl md:text-4xl xl:text-5xl mt-10 md:mt-0 font-semibold text-gray-700">
                    {room.name}
                  </h1>

                  <div className="p-5 rounded-md shadow-lg md:ml-auto flex flex-col lg:flex-row items-center">
                    <p className="text-sm lg:text-lg uppercase tracking-widest">
                      {t("rooms_preview.starting_from")} &nbsp;
                    </p>
                    <p className="text-2xl lg:text-4xl text-green-500 font-semibold">
                      {parseFloat(room.price).toFixed(2)} &euro;
                    </p>
                  </div>
                </div>
                <div className="px-0 flex flex-row items-center justify-center md:justify-start gap-3">
                  {[...Array(room.occupancy)].map((e, i) => (
                    <FontAwesomeIcon
                      icon={faMale}
                      className=" text-2xl"
                      {...css({
                        color: tcolors[config.color + "-600"],
                      })}
                    />
                  ))}

                  {room.is_most_requested && (
                    <div
                      {...css({
                        backgroundColor: tcolors[config.color + "-500"],
                      })}
                      className="ml-auto text-xs lg:text-lg p-2 px-3 font-semibold uppercase text-white rounded-full"
                    >
                      {t("room.is_more_requested")}
                    </div>
                  )}
                </div>

                <div className="bg-gray-300 w-full h-[1px] my-4" />
                <RoomCarousel />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                  <div>
                    <h1 className="uppercase tracking-wider text-2xl">
                      {t("room.description")}
                    </h1>
                    <p
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: room.category.descr }}
                    />

                    <div className="border-b border-b-gray-300 h-1 w-full my-5" />

                    <h1 className="uppercase tracking-wider text-2xl mb-4">
                      {t("room.services")}
                    </h1>
                    <div className="w-full flex flex-row gap-4 flex-wrap mb-5 md:mb-0">
                      {room.services?.map((service) => (
                        <div className="w-auto">
                          <div className="flex flex-row items-center">
                            <ServiceRoom index={service} color={config.color} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col gap-3">
                    {room.tip_obj.text && (
                      <div
                        className="p-5 rounded-lg
                      flex flex-row items-center gap-2"
                        {...css({
                          color: tcolors[config.color + "-600"],
                          backgroundColor: tcolors[config.color + "-50"],
                        })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.6"
                          stroke="currentColor"
                          class="w-8 h-8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                          />
                        </svg>
                        <p className="italic text-xl">{room.tip_obj.text}</p>
                      </div>
                    )}

                    <div className="bg-yellow-300 p-4 flex flex-col rounded-lg gap-4">
                      <h2 className="uppercase text-3xl tracking-widest font-medium">
                        {t("room.booking")}
                      </h2>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="flex flex-row gap-2 text-lg">
                          <p className="normal"> {t("room.checkin")}&nbsp;</p>
                          <b>{room.checkin}</b>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-2 text-lg">
                            <p className="font-light">
                              {t("room.checkout")}&nbsp;
                            </p>
                            <b>{room.checkout}</b>
                          </div>
                          <p
                            className="uppercase text-xs"
                            {...css({
                              color: tcolors[config.color + "-600"],
                            })}
                          >
                            {t("room.only_web_book")}
                          </p>
                        </div>
                      </div>
                      <div className="h-1 border-b border-b-white w-full" />
                      <div className="bg-white p-5 rounded-lg flex flex-row items-center">
                        <p className="uppercase text-md text-gray-600 tracking-widest ">
                          {t("rooms_preview.starting_from")}
                        </p>
                        <span className="ml-auto text-3xl font-semibold text-green-500 tracking-wide">
                          {parseFloat(room.price).toFixed(2)} &euro;
                        </span>
                      </div>
                      <a
                        href={
                          "https://www.idabooking.eu/Home/search_base?uuid=" +
                          uuid
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="p-5 text-white rounded-lg text-lg font-medium
                  tracking-widest uppercase text-center transition"
                        {...css({
                          backgroundColor: tcolors[config.color + "-500"],
                          ":hover": {
                            backgroundColor: tcolors[config.color + "-600"],
                          },
                        })}
                      >
                        {t("room.book_a_room")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy uuid={uuid} color={config.color} />
      <ButtonBar uuid={uuid} color={config.color} />
    </StyleProvider>
  );
}

export default Room;
