import { css } from "glamor";
import React from "react";
import { tcolors } from "../config/Colors";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
import { useTranslation } from "react-i18next";

function Advisor({ id_advisor, color, image }) {
  const { t } = useTranslation("common");

  const [isLoading, setIsLoading] = React.useState(true);
  const [advisor, setAdvisor] = React.useState({});

  React.useEffect(() => {
    if (id_advisor) fetchAdvisor();
  }, [t]);

  const fetchAdvisor = async () => {
    setIsLoading(true);
    webService.getAdvisor(id_advisor).then(
      (result) => {
        const status = result.status;
        if (status === 200) setAdvisor(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center py-4">
          <LoadingIndicator />
        </div>
      ) : (
        <section
          className="flex items-center justify-center h-96 bg-fixed bg-center bg-cover p-5"
          style={{
            backgroundImage: `url("${image}")`,
          }}
        >
          <div className="bg-white">
            <div
              className="max-w-7xl shadow-md
        p-10 flex flex-col justify-center items-center text-center"
            >
              <h1 className="text-2xl font-bold tracking-wider uppercase text-gray-700 mb-3 text-center">
                {advisor.title_text_obj.text}
              </h1>

              {advisor.translation_text_obj.text}

              <div
                onClick={() => window.open(advisor.action_url, "_blank")}
                className="p-4 uppercase text-white
            tracking-wide text-xl font-semibold mt-6 px-6 shadow-md hover:shadow-lg cursor-pointer 
            transition"
                {...css({
                  backgroundColor: tcolors[color + "-500"],
                  ":hover": { backgroundColor: tcolors[color + "-600"] },
                })}
              >
                {advisor.action_text_obj.text}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Advisor;
